#not-found-section {
  background-color: black;
  /* /* height: 100%; */
  min-height: 100vh;
  color: #f8f9fa;
}

#not-found-section h1 {
  font-size: 180px;
}
